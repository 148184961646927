import React from "react";
import styled from "styled-components";

export const Svg = styled.svg`
  color: ${({ color }) => color ?? `inherit`};
  flex-shrink: 0;
  transition: color 0.2s ease;
  vertical-align: middle;
`;

const EncoreMieuxSigle = ({ color }: { color?: string }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 755.905 1074.423"
      height="1074.423"
      width="755.905"
      fill="none"
      color={color}
    >
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M0 805.817h566.929V0H0Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 1074.423)">
        <path
          d="M227.786 155.22c-19.767 2.503-21.771 5.255-21.771 22.522V315.12c0 52.049 17.015 90.083 41.289 90.083 14.264 0 21.77-10.26 21.77-32.531v-194.93c0-17.267-2.252-20.02-21.77-22.521v-5.005h72.818v5.005c-19.767 2.502-21.771 5.254-21.771 22.52v138.63c0 49.296 15.014 88.833 40.038 88.833 15.263 0 22.52-9.51 22.52-32.28V177.743c0-17.267-2.252-20.02-21.77-22.521v-5.005h72.818v5.005c-19.518 2.502-21.768 5.254-21.768 22.52v193.68c0 30.779-14.514 45.292-40.29 45.292-26.524 0-46.543-17.765-54.051-65.058l-4.503.998c6.757 47.546-4.753 64.06-33.03 64.06-25.524 0-46.044-17.264-54.302-65.058l-4.752 1.25c6.004 34.782 7.507 48.545 8.005 63.808h-4.504c-10.76-11.257-22.77-15.263-47.794-18.266v-5.005c19.518-2.502 21.77-5.254 21.77-22.518V177.742c0-17.267-2.252-20.02-21.77-22.521v-5.005h72.818Zm172.738 439.629h-211.48v43.48h196.484v17.79H189.043v44.322h211.481v18.632H166.4V576.218h234.124z"
          fill="currentColor"
        />
        <path
          d="M517.821 538.497H49.099v218.214h468.722zm0-489.39H49.099v468.55h468.722zm20.755 489.39v238.968H28.346V28.349h510.23v489.307h.006v20.841z"
          fill="currentColor"
        />
      </g>
    </Svg>
  );
};

export default EncoreMieuxSigle;
