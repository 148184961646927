"use client";

import { AriaLinkOptions, useLink } from "@react-aria/link";
import NextLink from "next/link";
import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { Route } from "~/core/routes";
import { FocusRing } from "~/guidelines/Focus";

const StyledLink = styled(NextLink)<{
  kind?: "primary" | "secondary" | "tertiary" | "no-color";
}>`
  & svg,
  & img {
    vertical-align: middle;
    text-decoration: none;
  }

  & svg {
    max-width: 16px;
    max-height: 16px;
  }

  color: currentColor;

  ${({ kind }) =>
    kind === "primary" &&
    css`
      color: ${({ theme }) => theme.color.text.lightTertiary};
    `};

  ${({ kind }) =>
    kind === "secondary" &&
    css`
      color: ${({ theme }) => theme.color.text.lightSecond};
    `};

  ${({ kind }) =>
    kind === "tertiary" &&
    css`
      color: ${({ theme }) => theme.color.text.darkSecond};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    `};

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const Link = ({
  kind = "primary",
  href,
  target,
  locale,
  children,
  ...props
}: AriaLinkOptions & {
  kind?: "primary" | "secondary" | "tertiary" | "no-color";
  href: Route;
  target?: string;
  rel?: "noopener";
  locale?: AVAILABLE_LOCALES;
  children: React.ReactNode;
}) => {
  const ref = useRef(null);
  const { linkProps } = useLink(props, ref);

  return (
    <FocusRing>
      <StyledLink
        {...linkProps}
        ref={ref}
        href={href}
        passHref
        locale={locale}
        kind={kind}
        target={target}
        className={props["aria-describedby"] ?? undefined}
      >
        {children}
      </StyledLink>
    </FocusRing>
  );
};

export default Link;
