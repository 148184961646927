import { Trans } from "@lingui/macro";
import React from "react";
import styled from "styled-components";
import { AVAILABLE_LOCALES } from "~/core/locale";
import {
  gogailleLegalMentionsRoute,
  gogaillePrivacyCookiesRoute,
  gogaillePrivacyPolicyRoute,
  gogailleTermsGeneralConditionsRoute,
} from "~/core/routes";
import { Flex } from "~/guidelines/Flex";
import Instagram from "~/guidelines/Icon/Social/Instagram";
import LinkedIn from "~/guidelines/Icon/Social/LinkedIn";
import Link from "~/guidelines/Link";
import LocaleSwitcher from "~/guidelines/LocaleSwitcher";
import EncoreMieuxSigle from "~/guidelines/Logo/EncoreMieuxSigle";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body2, caption1, heading3 } from "~/guidelines/Typography";

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.color.background.darkFirst};
  width: 100%;
`;

const CopyrightArea = styled.div``;

const FooterLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(7, 7)};

  color: ${({ theme }) => theme.color.text.darkFirst};

  ${mediaQueries.sm()} {
    max-width: 500px;
    padding: ${({ theme }) => theme.spacing(9, 9, 12, 9)};
    gap: ${({ theme }) => theme.spacing(7)};
  }

  ${mediaQueries.md()} {
    gap: 150px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    max-width: 1240px;

    & > ${CopyrightArea} {
      display: none;
    }
  }
`;

const BrandArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  & > ${CopyrightArea} {
    display: none;

    ${mediaQueries.md()} {
      display: block;
    }
  }

  ${mediaQueries.md()} {
    width: 50%;
  }

  ${mediaQueries.lg()} {
    width: 40%;
  }
`;

const Branding = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(8)};
`;

const Logo = styled.div`
  color: ${({ theme }) => theme.color.text.darkFirst};
  & svg {
    width: 40px !important;
    height: auto;

    ${mediaQueries.md()} {
      width: 64px !important;
    }
  }
`;

const InformationArea = styled.div`
  padding-top: ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  ${mediaQueries.sm()} {
    gap: ${({ theme }) => theme.spacing(7)};
  }

  ${mediaQueries.md()} {
    flex-grow: 1;
  }

  ${mediaQueries.lg()} {
    flex-direction: row;
  }

  ${mediaQueries.xl()} {
    align-items: flex-start;
  }
`;

const LinksArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: ${({ theme }) => theme.spacing(6)};

  ${mediaQueries.sm()} {
    gap: ${({ theme }) => theme.spacing(7)};
  }

  ${mediaQueries.md()} {
    flex-grow: 1;
  }
`;

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: ${({ theme }) => theme.spacing(4)};
  list-style: none;
`;

const LinkItem = styled.li`
  margin: 0;
  padding: 0;

  ${body2};

  & > a {
    color: ${({ theme }) => theme.color.text.darkFirst};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LocaleSelectorArea = styled.div`
  width: max-content;
  margin-left: -12px;

  ${mediaQueries.md()} {
    margin-top: -12px;
    margin-left: 0;
  }
`;

const Catchphrase = styled.div`
  ${heading3};
  color: ${({ theme }) => theme.color.text.darkFirst};
`;

const Copyright = styled.p`
  margin: 0;
  ${caption1};
  color: ${({ theme }) => theme.color.text.darkThird};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

const Socials = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(6)};
  color: ${({ theme }) => theme.color.text.darkThird};

  && svg {
    max-width: 24px;
    max-height: 24px;
  }

  a {
    color: ${({ theme }) => theme.color.text.darkSecond};
  }
`;

export type FooterProps = {
  currentLocale: AVAILABLE_LOCALES;
  availableLocales: AVAILABLE_LOCALES[];
  pressKitUrl: string | null;
  onLocaleChange: (newLocale: AVAILABLE_LOCALES) => void;
};

const Footer = ({
  currentLocale,
  availableLocales,
  pressKitUrl,
  onLocaleChange,
}: FooterProps) => {
  const copyrightArea = (
    <CopyrightArea>
      <Copyright>
        <Trans>
          {"\u00a9"} {new Date().getUTCFullYear()} Encore Mieux. All right
          reserved.
        </Trans>
      </Copyright>
    </CopyrightArea>
  );

  return (
    <FooterContainer>
      <FooterLayout>
        <BrandArea>
          <Branding>
            <Logo>
              <EncoreMieuxSigle />
            </Logo>

            <Content>
              <Catchphrase>{`Encore Mieux à chaque instant.`}</Catchphrase>
            </Content>
          </Branding>

          {copyrightArea}
        </BrandArea>

        <InformationArea>
          <LinksArea>
            <LinkList>
              <LinkItem>
                <Link
                  kind="tertiary"
                  href="mailto:bonjour@encore-mieux.com?subject=Je%20souhaite%20vous%20contacter%20au%20sujet%20de%20Encore%20Mieux"
                >
                  <Trans>Write us</Trans>
                </Link>
              </LinkItem>
              <LinkItem>
                <a href="tel:+33974993232">+33 9 74 99 32 32</a>
              </LinkItem>
            </LinkList>
            <LinkList>
              <LinkItem>
                <Link
                  kind="tertiary"
                  href="https://gogaille.flatchr.io/"
                  target="_blank"
                  rel="noopener"
                >
                  <Trans>Careers</Trans>
                </Link>
              </LinkItem>
              {pressKitUrl !== null ? (
                <LinkItem style={{ display: "none" }}>
                  <Link
                    kind="tertiary"
                    href={pressKitUrl}
                    target="_blank"
                    rel="noopener"
                  >
                    <Trans>Press kit</Trans>
                  </Link>
                </LinkItem>
              ) : undefined}
              <LinkItem>
                <a
                  href={`/${currentLocale}${gogailleTermsGeneralConditionsRoute()}`}
                >
                  <Trans>General conditions</Trans>
                </a>
              </LinkItem>
              <LinkItem>
                <a href={`/${currentLocale}${gogailleLegalMentionsRoute()}`}>
                  <Trans>Legal information</Trans>
                </a>
              </LinkItem>
              <LinkItem>
                <a href={`/${currentLocale}${gogaillePrivacyPolicyRoute()}`}>
                  <Trans>Privacy Policy</Trans>
                </a>
              </LinkItem>
              <LinkItem>
                <a href={`/${currentLocale}${gogaillePrivacyCookiesRoute()}`}>
                  <Trans>Cookie Usage Policy</Trans>
                </a>
              </LinkItem>
              <LinkItem>
                <a href="javascript:openAxeptioCookies()">
                  <Trans>Cookies preferences</Trans>
                </a>
              </LinkItem>
            </LinkList>
          </LinksArea>

          <Socials>
            <Link
              href="https://fr.linkedin.com/company/nouvellepage-encoremieux"
              aria-label="LinkIn"
              rel="noopener"
              target="_blank"
            >
              <LinkedIn />
            </Link>
            <Link
              href="https://www.instagram.com/encoremieuxici/"
              aria-label="Instagram"
              rel="noopener"
              target="_blank"
              aria-describedby="footer-link"
            >
              <Instagram />
            </Link>
          </Socials>

          <LocaleSelectorArea>
            <LocaleSwitcher
              currentLocale={currentLocale}
              availableLocales={availableLocales}
              onLocaleChange={onLocaleChange}
              variant="dark"
            />
          </LocaleSelectorArea>
        </InformationArea>

        {copyrightArea}
      </FooterLayout>
    </FooterContainer>
  );
};

export default Footer;
